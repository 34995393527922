import React from "react"
import {Navbar, Nav, Container} from "react-bootstrap"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  let year = new Date().getFullYear()

  if (location.pathname === rootPath) {
    header = (
      <Navbar collapseOnSelect className="navbar-custom" expand="lg" bg="dark" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand className="mr-auto" href="#home">
            <img src={require('../../static/images/logo.png')} className="img-fluid" alt="infinicube logo"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto mt-2 mt-lg-0" as="ul">
              <Nav.Item as="li" className="mb-0">
                <Nav.Link href="#home">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="mb-0">
                <Nav.Link href="#services">Services</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="mb-0">
                <Nav.Link href="#blog">Blog</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="mb-0">
                <Nav.Link href="#contact">Contact</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  } else {
    header = (
      <Navbar collapseOnSelect className="navbar-custom" expand="lg" bg="dark" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand className="mr-auto" href="/">
            <img src={require('../../static/images/logo.png')} className="img-fluid" alt="infinicube logo"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto mt-2 mt-lg-0" as="ul">
              <Nav.Item as="li" className="mb-0">
                <Nav.Link href="/">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="mb-0">
                <Nav.Link href="/#services">Services</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="mb-0">
                <Nav.Link href="/#blog">Blog</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="mb-0">
                <Nav.Link href="/#contact">Contact</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }



  let footer = (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <a href="#home" className="footer-logo" data-sal="slide-up">
              <img src={require('../../static/images/logo.png')} className="img-fluid" alt="infinicube logo" />
            </a>
            <ul className="social-links">
              <li><a href="https://www.facebook.com/infinicube/" target="_blank" rel="noreferrer" data-sal="slide-up" data-sal-delay="100">Facebook</a></li>
              <li><a href="https://twitter.com/infinicube/" target="_blank" rel="noreferrer" data-sal="slide-up" data-sal-delay="200">Twitter</a></li>
              <li><a href="https://www.linkedin.com/company/infinicube/" target="_blank" rel="noreferrer" data-sal="slide-up" data-sal-delay="300">Linkedin</a></li>
              <li><a href="https://www.instagram.com/infinicube_/" target="_blank" rel="noreferrer" data-sal="slide-up" data-sal-delay="400">Instagram</a></li>
            </ul>

            <p className="copyright">
              © { year } Infinicube, All Rights Reserved.
            </p>

          </div>

        </div>
      </div>
    </footer>
  )

  return (
    <div>
      {/*<div id="loading">
        <div id="loading-center">
          <div id="loading-center-absolute">
            <div className="box">
              <div className="blobs">
                <div className="blob"></div>
                <div className="blob"></div>
              </div>

              <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                  <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"/>
                    <feColorMatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo"/>
                    <feBlend in="SourceGraphic" in2="goo"/>
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>*/}

      {header}

      {children}

      {footer}

      {/*<div className="scroll-up">*/}
      {/*  <Link to={'/#home'} id="scroll-up"><i className="fas fa-arrow-up"></i></Link>*/}
      {/*</div>*/}
    </div>
  )
}

export default Layout
